<template>
  <div>
    <form v-on:submit.prevent="onSubmit">
      <h1 class="h3 mb-3 fw-normal">Password generator</h1>

      <div class="spaced form-floating">
        <input type="text" ref="resource" class="form-control" id="resource" placeholder="Resource" v-model="resource">
        <label for="resource">Resource</label>
      </div>

      <div class="spaced form-floating input-group">
        <input :type="generatedFieldType" class="form-control" id="password" placeholder="Generated password will be here" v-model="password" readonly>
        <button ref="copyButton" class="btn btn-outline-secondary" type="button" id="copy-button" v-show="password">Copy</button>
        <label for="password">Generated password</label>
      </div>

      <div class="spaced form-check">
        <input v-model="passwordVisible" class="form-check-input" type="checkbox" id="flexCheckChecked">
        <label class="form-check-label" for="flexCheckChecked">
          Show password
        </label>
      </div>

      <div class="spaced">
        <button class="w-100 btn btn-lg btn-primary" type="submit">Generate</button>
      </div>

    </form>
  </div>
</template>

<script>
let ClipboardJS = require("clipboard")
new ClipboardJS('.btn');

export default {
  name: 'Generator',
  props: ['secret'],
  mounted() {
    this.$refs.resource.focus()
  },
  data() {
    return {
      resource: "",
      password: "",
      passwordVisible: false,
    }
  },
  methods: {
    async digestMessage(message) {
      const encoder = new TextEncoder()
      const data = encoder.encode(message)
      return await crypto.subtle.digest('SHA-256', data)
    },
    async onSubmit() {
      this.password = ""
      let alphabet = "0123456789abcdefghijklmnoqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"

      let domain = this.resource.toLowerCase().trim()
      let s = this.secret + domain + this.secret
      let d = await this.digestMessage(s)
      let q = new Int32Array(d);

      q.forEach((word) => {
        let o = word >>> 1
        let r = ""
        while (o !== 0) {
          let m = o % alphabet.length
          r = (alphabet.substring(m, m + 1)) + "" + r
          o = Math.floor(o / alphabet.length)
        }
        this.password += r.substring(2)
      })
      this.$refs.copyButton.setAttribute('data-clipboard-text', this.password)
    },
  },
  computed: {
    generatedFieldType() {
      return this.passwordVisible ? "text" : "password"
    }
  }
}
</script>

<style scoped>
.spaced {
  margin: 1em 0;
}
</style>
