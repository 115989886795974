<template>
  <div class="container d-flex flex-column h-100">
    <main class="form-signin" v-if="realSecret === ''">
      <form v-on:submit.prevent="onSubmit">
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

        <div class="form-floating">
          <input type="password" ref="secret" class="form-control" id="floatingPassword" placeholder="Secret" v-model="secret">
          <label for="floatingPassword">Your secret</label>
        </div>

        <div class="alert alert-danger text-center small" role="alert" v-show="weakSecret">
          Secret must be at least 8 characters!
        </div>

        <button class="w-100 btn btn-lg btn-primary" type="submit">Continue</button>
      </form>
    </main>
    <main v-else>
      <Generator :secret="secret" />
    </main>
    <footer class="footer mt-auto py-3">
      <div class="container text-center">
        <span class="text-muted">Äni, {{ yearSign }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
import Generator from "@/components/Generator"

export default {
  name: "Page",
  components: {
    Generator,
  },
  mounted() {
    this.$refs.secret.focus()
  },
  data() {
    return {
      secret: "",
      realSecret: "",
      weakSecret: false
    }
  },
  methods: {
    onSubmit() {
      if (this.secret.length < 8) {
        this.weakSecret = true
        return false
      }
      this.realSecret = this.secret
    },
  },
  computed: {
    yearSign() {
      let currentTime = new Date()
      let year = currentTime.getFullYear()
      if (year !== 2021) {
        year = '2021-' + year
      }
      return year
    }
  }
}
</script>

<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
html,
body, #app {
  height: 100%;
}

body {
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>